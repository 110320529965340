import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionArticle.module.css';
import RecentBlogsSmallcard from '../../../../components/RecentBlogsSmallCard/RecentBlogsSmallcard';
import { useState } from 'react';
import { SINGLE_BLOG_PAGE_ID } from '../../../SingleBlogPage/SingleBlogPage.duck';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionArticleForBlog = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    pageId,
    allBlogsSection,
  } = props;

  const [activeBlock, setActiveBlock] = useState(null);
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const singleBlogPage = pageId === SINGLE_BLOG_PAGE_ID;
  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;
  const backgroundColor = appearance?.backgroundColor;

  const originalArray = allBlogsSection?.blocks?.length > 0 ? [...allBlogsSection?.blocks] : [];
  const recentsBlogsReversed = originalArray?.reverse();
  const tempArray = recentsBlogsReversed?.length > 0 ? [...recentsBlogsReversed] : [];
  const recentBlogs = tempArray?.slice(0, 4);

  const handleClick = id => {
    setActiveBlock(id);
  };

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      contentDivClassName={singleBlogPage ? css['singleblogpage-content-wrapper'] : ''}
      appearance={appearance}
      options={fieldOptions}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <>
        {singleBlogPage ? (
          <>
            <div className={css['table-of-content']}>
              <h3 className={css.heading}>Table of Content</h3>
              <div className={css.content_sec_links}>
                {blocks?.map(block => {
                  const isActive = block?.blockId === activeBlock;
                  return (
                    <React.Fragment key={block.blockId}>
                      <a
                        className={`${css.section_link} ${isActive ? css.active : ''}`}
                        href={`#${block.blockId}`}
                        onClick={() => handleClick(block.blockId)}
                      >
                        {block.blockName}
                      </a>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field
              data={description}
              className={defaultClasses.description}
              options={fieldOptions}
            />
            <Field
              data={callToAction}
              className={defaultClasses.ctaButton}
              options={fieldOptions}
            />
          </header>
        ) : null}
        {hasBlocks ? (
          <div
            className={classNames(defaultClasses.blockContainer, css.articleMain, {
              [css.noSidePaddings]: isInsideContainer,
              [css.singleBlogPage]: singleBlogPage,
            })}
          >
            <BlockBuilder
              blocks={blocks}
              sectionId={sectionId}
              ctaButtonClass={defaultClasses.ctaButton}
              options={options}
              isSingleBlogPage={singleBlogPage}
            />
          </div>
        ) : null}
        {singleBlogPage && recentBlogs?.length > 0 ? (
          <div className={css['recent-blogs-sec']}>
            <h3 className={css.heading}>Recent Blogs</h3>
            <RecentBlogsSmallcard recentBlogs={recentBlogs} />
          </div>
        ) : null}
      </>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionArticleForBlog.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticleForBlog.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionArticleForBlog;
