import React from 'react';

const IconLinkedin = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1788_1080)">
        <path
          d="M23.0002 21.5844H23.2272L22.7922 20.9264C23.0582 20.9264 23.1992 20.7574 23.2012 20.5504C23.2012 20.5424 23.2012 20.5334 23.2002 20.5254C23.2002 20.2434 23.0302 20.1084 22.6812 20.1084H22.1172V21.5844H22.3292V20.9414H22.5902L23.0002 21.5844ZM22.5752 20.7744H22.3292V20.2754H22.6412C22.8022 20.2754 22.9862 20.3014 22.9862 20.5124C22.9862 20.7544 22.8002 20.7744 22.5742 20.7744"
          fill="black"
        />
        <path
          d="M17.291 19.0734H14.284V14.3644C14.284 13.2414 14.264 11.7964 12.72 11.7964C11.154 11.7964 10.914 13.0194 10.914 14.2834V19.0734H7.908V9.38936H10.795V10.7124H10.835C11.424 9.70636 12.518 9.10536 13.683 9.14836C16.731 9.14836 17.292 11.1534 17.292 13.7604L17.291 19.0734ZM4.515 8.06536C3.551 8.06536 2.77 7.28436 2.77 6.32036C2.77 5.35636 3.551 4.57536 4.515 4.57536C5.479 4.57536 6.26 5.35636 6.26 6.32036C6.26 7.28436 5.479 8.06536 4.515 8.06536ZM6.018 19.0734H3.008V9.38936H6.018V19.0734ZM18.79 1.78336H1.497C0.68 1.77436 0.01 2.42936 0 3.24636V20.6104C0.01 21.4284 0.68 22.0834 1.497 22.0744H18.79C19.609 22.0844 20.282 21.4294 20.293 20.6104V3.24536C20.281 2.42636 19.608 1.77136 18.79 1.78236"
          fill="black"
        />
        <path
          d="M22.6045 19.4512C21.8405 19.4582 21.2265 20.0842 21.2345 20.8482C21.2415 21.6122 21.8675 22.2262 22.6315 22.2182C23.3955 22.2112 24.0095 21.5852 24.0015 20.8212C23.9945 20.0672 23.3845 19.4582 22.6315 19.4512H22.6045ZM22.6365 22.0592C21.9665 22.0702 21.3825 21.5372 21.3715 20.8672C21.3605 20.1972 21.8945 19.6452 22.5645 19.6342C23.2345 19.6232 23.7865 20.1572 23.7975 20.8272C23.7975 20.8342 23.7975 20.8402 23.7975 20.8472C23.8115 21.5022 23.2915 22.0452 22.6365 22.0592H22.6055H22.6365Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1788_1080">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconLinkedin;
