import React from 'react';
import css from './style.module.css';
import Field from '../../containers/PageBuilder/Field';
import IconArrowRight from '../IconArrowRight/IconArrowRight';
import { Link } from 'react-router-dom';

const RecentBlogsSmallcard = ({ recentBlogs = [] }) => {
  return (
    <div className={css.recent_blogs_wrapper}>
      {recentBlogs?.map((blog, index) => {
        return <Card key={index} blog={blog} />;
      })}
    </div>
  );
};

const Card = ({ blog = {} }) => {
  return (
    <div className={css.card_wrapper}>
      <div className={css.card_img}>
        <Field data={blog?.media} />
      </div>
      <div className={css.card_content}>
        <Field data={blog?.title} />

        <Link to={blog?.callToAction?.href}>
          <span>{blog?.callToAction?.content}</span>
          <IconArrowRight />
        </Link>
      </div>
    </div>
  );
};

export default RecentBlogsSmallcard;
