import React, { useState } from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionColumns.module.css';
import {
  BLOGSPAGE_POSTS_SECTION_NAME,
  ASSET_NAME as BLOG_PAGE_ID,
} from '../../../BlogsPage/BlogsPage.duck';
import BlogPageBlockBuilder from '../../BlockBuilder/BlogPageBlockBuilder';
import { IconArrowHead } from '../../../../components';
import { SINGLE_BLOG_PAGE_ID } from '../../../SingleBlogPage/SingleBlogPage.duck';

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 265px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionColumns = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    pageId,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;
  const isBlogsPage = pageId === BLOG_PAGE_ID;
  const isSingleBlogsPage = pageId === SINGLE_BLOG_PAGE_ID;
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 9;
  const handleClick = event => {
    setCurrentPage(Number(event.target.id));
  };
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentBlocks = blocks.slice(indexOfFirstPost, indexOfLastPost);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(blocks.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePrevious = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleNext = () => {
    setCurrentPage(prevPage =>
      prevPage < Math.ceil(blocks.length / postsPerPage) ? prevPage + 1 : prevPage
    );
  };

  return (
    <SectionContainer
      id={isBlogsPage ? BLOGSPAGE_POSTS_SECTION_NAME : sectionId}
      className={`${className} ${css[isBlogsPage ? 'bg-blogsection' : '']} ${
        css[isSingleBlogsPage ? 'singleblogpage-column-sec-bg' : '']
      }`}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
      {hasBlocks ? (
        <>
          <div
            className={`${classNames(defaultClasses.blockContainer, getColumnCSS(numColumns), {
              [css.noSidePaddings]: isInsideContainer,
            })} ${isBlogsPage || isSingleBlogsPage ? css['blogspage-custom-style'] : ''}`}
          >
            {isBlogsPage || isSingleBlogsPage ? (
              <BlogPageBlockBuilder
                ctaButtonClass={defaultClasses.ctaButton}
                blogPageClassName={isBlogsPage || isSingleBlogsPage ? 'blogspage-style' : ''}
                pageId={pageId}
                blocks={currentBlocks}
                sectionId={sectionId}
                responsiveImageSizes={getResponsiveImageSizes(numColumns)}
                options={options}
              />
            ) : (
              <BlockBuilder
                ctaButtonClass={defaultClasses.ctaButton}
                pageId={pageId}
                blocks={blocks}
                sectionId={sectionId}
                responsiveImageSizes={getResponsiveImageSizes(numColumns)}
                options={options}
              />
            )}
          </div>
          {isBlogsPage && currentBlocks?.length > 9 ? (
            <div className={css.custom_pagination}>
              <button onClick={handlePrevious} disabled={currentPage === 1}>
                <IconArrowHead direction="left" size="big" />
              </button>
              <ul>
                {pageNumbers?.map(number => {
                  return (
                    <li
                      key={number}
                      id={number}
                      className={currentPage === number ? css.active_page : ''}
                      onClick={handleClick}
                    >
                      {number}
                    </li>
                  );
                })}
              </ul>
              <button onClick={handleNext} disabled={currentPage === pageNumbers.length}>
                <IconArrowHead direction="right" size="big" />
              </button>
            </div>
          ) : null}
        </>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionColumns.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionColumns.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionColumns;
