import React from 'react';

const IconX = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM10.3423 12.7171L4.60646 5.04785H9.02721L12.7875 10.0757L17.4414 5.04785H18.7405L13.3679 10.852L19.4266 18.9526H15.0059L10.923 13.4932L5.86946 18.9526H4.57031L10.3423 12.7171ZM8.54779 6.0047H6.51692L15.4851 17.9957H17.5159L8.54779 6.0047Z"
        fill="black"
      />
    </svg>
  );
};

export default IconX;
