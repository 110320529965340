import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import { Link } from 'react-router-dom';
import css from './BlockDefault.module.css';
import { ASSET_NAME as BLOG_PAGE_ID } from '../../../BlogsPage/BlogsPage.duck';
import IconArrowRight from '../../../../components/IconArrowRight/IconArrowRight';
import IconMeta from '../../../../components/IconMeta/IconMeta';
import IconInsta from '../../../../components/IconInsta/IconInsta';
import IconX from '../../../../components/IconX/IconX';
import IconLinkedin from '../../../../components/IconLinkedin/IconLinkedin';
import SocialMediaSharingOfBlog from '../../../../components/SocialMediaSharingOfBlog/SocialMediaSharingOfBlog';
import { useLocation } from 'react-router-dom';

const FieldMedia = props => {
  const { className, media, sizes, options, isBlogsPage, isSingleBlogPage } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div
      className={`${classNames(className, css.media)} ${isBlogsPage ? css['blogpage-image'] : ''} ${
        isSingleBlogPage ? css.single_blogpage_img : ''
      }`}
    >
      <Field
        data={media}
        sizes={sizes}
        options={options}
        isBlogsPage={isBlogsPage || isSingleBlogPage}
      />
    </div>
  ) : null;
};

const BlockDefaultForSingleBlog = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    mainSectionClassName,
    blogPageClassName,
    pageId,
    isSingleBlogPage,
    sectionId,
  } = props;
  const location = useLocation();
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const isBlogsPage = pageId === BLOG_PAGE_ID;
  const rootURLForSeo = process.env.REACT_APP_ROOT_URL_FOR_SEO;
  const blogUrl = `${rootURLForSeo}${location?.pathname}`;

  return (
    <BlockContainer
      id={blockId}
      className={`${classes} ${mainSectionClassName} ${css[blogPageClassName]}`}
    >
      <div className={`${css.inner_block} ${isSingleBlogPage ? css.inner_block_blogpage : ''}`}>
        <div className={css.booking_btn}>
          {hasTextComponentFields ? (
            sectionId === 'section-1' ? (
              <>
                <div className={css.blog_header}>
                  {callToAction?.content ? (
                    <div className={css.back_btn}>
                      <Link to={callToAction.href}>
                        <IconArrowRight color="white" />
                        <span>{callToAction?.content}</span>
                      </Link>
                    </div>
                  ) : null}
                  <SocialMediaSharingOfBlog blogTitle={title} blogUrl={blogUrl} />
                </div>
              </>
            ) : (
              <Field data={callToAction} className={ctaButtonClass} options={options} />
            )
          ) : null}
        </div>

        {hasTextComponentFields ? (
          <div
            className={`${classNames(
              textClassName,
              mainSectionClassName === 'section-4' ? css.card_text : css.text
            )}`}
          >
            {mainSectionClassName === 'section-4' ? (
              <>
                <Field className={css.name} data={text} options={options} />
                <Field data={title} options={options} />
              </>
            ) : (
              <>
                <div className={css.main_heading}>
                  <Field data={title} options={options} />
                </div>
                <Field className={css.name} data={text} options={options} />
              </>
            )}
          </div>
        ) : null}
        <FieldMedia
          media={media}
          sizes={responsiveImageSizes}
          className={mediaClassName}
          options={options}
          isBlogsPage={isBlogsPage}
          isSingleBlogPage={isSingleBlogPage}
        />
      </div>
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefaultForSingleBlog.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefaultForSingleBlog.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefaultForSingleBlog;
