import React, { useCallback, useState } from 'react';
import css from './style.module.css';
import IconMeta from '../IconMeta/IconMeta';
import IconX from '../IconX/IconX';
import IconLinkedin from '../IconLinkedin/IconLinkedin';
import CopyIcon from '../../assets/copy-icon.png';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

const SocialMediaSharingOfBlog = ({ blogTitle, blogUrl }) => {
  const [copied, setCopied] = useState('');

  const hideCoppiedMessage = useCallback(() => {
    setTimeout(() => {
      setCopied('');
    }, 3000);
  }, [copied]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(blogUrl).then(() => {
      setCopied('URL copied to clipboard');
      hideCoppiedMessage();
    });
  };

  return (
    <>
      <div className={css.icons_div}>
        <span className={css.icon_text}>share article</span>
        <FacebookShareButton url={blogUrl} quote={blogTitle}>
          <IconMeta />
        </FacebookShareButton>
        <TwitterShareButton url={blogUrl} title={blogTitle}>
          <IconX />
        </TwitterShareButton>
        <LinkedinShareButton url={blogUrl} title={blogTitle}>
          <IconLinkedin />
        </LinkedinShareButton>
        <button className={css.copy_icon} onClick={copyToClipboard}>
          <img width={26} src={CopyIcon} alt="copy icon" />
        </button>
        {copied ? <div className={css.copied_text}>{copied}</div> : null}
      </div>
    </>
  );
};

export default SocialMediaSharingOfBlog;
